import {useEffect, useRef} from 'react'

import * as Sentry from '@sentry/nextjs'

import {trpc} from '../../../utils/trpc'
import {useUser} from '../../auth'
import {useGPT} from '../contexts/GPTContext'

/**
 * This hook tracks GAM ad impressions and clicks.
 */
export function useAdTracking() {
  const adDivsRef = useRef<
    Record<
      string,
      {
        adUnitPath: string
        advertiserId: string | null
        campaignId: string | null
        creativeId: string | null
        lineItemId: string | null
      }
    >
  >({})
  const {GPTHasLoaded} = useGPT()
  const {user, isLoading: isUserLoading} = useUser()
  const {mutate: track} =
    trpc.analytics.protectedTrackGenericUserEvent.useMutation()

  // ad slot information to corresponding div when slot renders have ended
  useEffect(() => {
    if (!GPTHasLoaded) return

    function onSlotRenderEnded(event: googletag.events.SlotRenderEndedEvent) {
      adDivsRef.current[event.slot.getSlotElementId()] = {
        adUnitPath: event.slot.getAdUnitPath(),
        advertiserId: event.advertiserId ? event.advertiserId.toString() : null,
        campaignId: event.campaignId ? event.campaignId.toString() : null,
        creativeId: event.creativeId ? event.creativeId.toString() : null,
        lineItemId: event.lineItemId ? event.lineItemId.toString() : null,
      }
    }

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded)
    })

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded)
      })
    }
  }, [GPTHasLoaded])

  // this hook sends a "Impression" event when the ad unit has been loaded.
  useEffect(() => {
    if (!GPTHasLoaded || !user) return

    async function onSlotRenderEnded(
      event: googletag.events.SlotRenderEndedEvent,
    ) {
      if (event.isEmpty || !user) return
      await track(
        {
          event: 'Impression',
          properties: {
            adUnitPath: event.slot.getAdUnitPath(),
            advertiserId: event.advertiserId
              ? event.advertiserId.toString()
              : null,
            campaignId: event.campaignId ? event.campaignId.toString() : null,
            creativeId: event.creativeId ? event.creativeId.toString() : null,
            lineItemId: event.lineItemId ? event.lineItemId.toString() : null,
          },
        },
        {
          onError: (e) => {
            if (process.env.NODE_ENV === 'development') {
              console.error(e)
            } else {
              Sentry.captureException(e)
            }
          },
        },
      )
    }

    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded)
    })

    return () => {
      googletag.cmd.push(() => {
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded)
      })
    }
  }, [GPTHasLoaded, track, user])

  // iframe event listener
  useEffect(() => {
    if (!GPTHasLoaded || !user) return

    async function iframeFunction() {
      setTimeout(async () => {
        if (
          document.activeElement &&
          document.activeElement.tagName === 'IFRAME'
        ) {
          // prevent the iframe from stealing focus and thus breaking tracking if user clicks on another iframe
          setTimeout(() => {
            window.focus()
          }, 1)
          if (
            // only track if the iframe is a google ad
            document.activeElement.id.startsWith('google_ads_iframe')
          ) {
            if (
              // we can only track and report on clicks if we are able to determine the ad div id;
              // this is only possible if the iframe's container element's parent is the ad div
              document.activeElement.parentElement?.parentElement?.id
            ) {
              const slot =
                adDivsRef.current[
                  document.activeElement.parentElement?.parentElement?.id
                ]
              if (slot) {
                await track(
                  {
                    event: 'Click',
                    properties: {
                      adUnitPath: slot.adUnitPath,
                      advertiserId: slot.advertiserId,
                      campaignId: slot.campaignId,
                      creativeId: slot.creativeId,
                      lineItemId: slot.lineItemId,
                    },
                  },
                  {
                    onError: (e) => {
                      if (process.env.NODE_ENV === 'development') {
                        console.error(e)
                      } else {
                        Sentry.captureException(e)
                      }
                    },
                  },
                )
              } else {
                if (process.env.NODE_ENV === 'development') {
                  console.error(
                    'Could not determine ad div id for iframe click tracking',
                    document.activeElement.id,
                    adDivsRef.current,
                  )
                } else {
                  Sentry.captureMessage(
                    'Could not determine ad div id for iframe click tracking',
                    {
                      extra: {
                        adDivs: adDivsRef.current,
                        iframeId: document.activeElement.id,
                      },
                    },
                  )
                }
              }
            } else {
              if (process.env.NODE_ENV === 'development') {
                console.error(
                  'Could not determine ad div id for iframe click tracking',
                  document.activeElement.id,
                  adDivsRef.current,
                )
              } else {
                Sentry.captureMessage(
                  'Could not determine ad div id for iframe click tracking',
                  {
                    extra: {
                      adDivs: adDivsRef.current,
                      iframeId: document.activeElement.id,
                    },
                  },
                )
              }
            }
          }
        }
      })
    }

    addEventListener('blur', iframeFunction)
    return () => removeEventListener('blur', iframeFunction)
  }, [GPTHasLoaded, isUserLoading, track, user])
}

import {MIXPANEL_PLATFORM as Platform, mixpanel} from '../mixpanel'
import {getDocumentTitle} from '../utils'

interface TrackSponsorClickArgs {
  sponsor: {
    name: string
    website?: string
  }
  source: string
  resourceSlug?: string
  resourceTitle?: string
}

export function trackSponsorClick({
  resourceSlug,
  sponsor,
  source,
  resourceTitle,
}: TrackSponsorClickArgs) {
  return function () {
    mixpanel.track('Clicked Sponsor Logo', {
      Platform,
      'Resource Slug': resourceSlug,
      'Resource Title': resourceTitle,
      'Sponsor Name': sponsor.name,
      'Sponsor Link': sponsor.website,
      Source: source,
      Title: getDocumentTitle(),
    })
  }
}

import type {Block, ImageAsset} from '@sanity/types'
import {z} from 'zod'

/**
 * Sanity Block type converted for use with Zod.
 *
 * Note that `z.any()` is a catch-all type that will allow any value.
 */
export const SanityBlock: z.ZodType<Block> = z.any()
const SanityImageAssetSchema: z.ZodType<ImageAsset> = z.any()

/**
 * Used when directly referencing an image field in a projection.
 */
export const SanityImage = z.object({
  _type: z.literal('image'),
  asset: SanityImageAssetSchema,
})
export type SanityImageType = z.infer<typeof SanityImage>

export const SanityImageWithDimensions = SanityImage.extend({
  dimensions: z.object({
    width: z.number(),
    height: z.number(),
  }),
})
export type SanityImageWithDimensionsType = z.infer<
  typeof SanityImageWithDimensions
>

// Can be used for referenced document objects (resource, story) as well as content builder objects
export const ContentBuilderSchema = z.object({
  _key: z.string().optional(),
  _id: z.string().optional(),
  _type: z.string().optional(),
  publishedAt: z.string().optional(),
  featuredImage: SanityImage,
  title: z.string(),
  slug: z.string().optional(),
  featuredText: z.array(SanityBlock),
  showButton: z.boolean().optional(),
  buttonText: z.string().optional(),
  isVideo: z.boolean().optional(),
  blank: z.boolean().optional(),
})

export const StickyFooterSchema = z.object({
  _id: z.string(),
  stickyFooterCTA: z.array(SanityBlock),
  stickyFooterContent: z.array(SanityBlock),
})

export type StickyFooterType = z.infer<typeof StickyFooterSchema>

import type {Dispatch, SetStateAction} from 'react'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import type {SubmitHandler} from 'react-hook-form'

import {useMutation} from '@tanstack/react-query'

import type {
  SubmitGatedContentData,
  SubmitGatedContentError,
  SubmitGatedContentVariables,
} from '../../core/api'
import {submitGatedContent} from '../../core/api'
import {
  HS_ROLE_CONSUMER,
  HS_ROLE_OPHTHALMOLOGIST,
  HS_ROLE_OPTOMETRIST,
} from '../../core/constants'
import {useExperimentVersion} from '../../core/hooks/useExperimentVersion'
import {scrollToElement} from '../../core/utils'
import {
  MIXPANEL_PLATFORM,
  MixpanelUserJourneyType,
  mixpanel,
} from '../analytics/mixpanel'
import {trackCompletedSignUp} from '../analytics/tracking/trackCompletedSignUp'
import {trackStartedSignUp} from '../analytics/tracking/trackStartedSignUp'
import {getDocumentTitle, getUTMsFromCookie} from '../analytics/utils'
import {AuthenticationFormState} from './AuthenticationForm'
import BooleanSelectInput from './BooleanSelectInput'
import CheckboxInput from './CheckboxInput'
import CountriesSelectInput from './CountriesSelectInput'
import FormError from './FormError'
import GraduationYearInput from './GraduationYearInput'
import RolesSelectInput from './RolesSelectInput'
import SpecialtiesSelectInput from './SpecialtiesSelectInput'

export type SignUpFormControl = Omit<SubmitGatedContentVariables, 'country'> & {
  country: {label: string; value: string}
}
export default function SignUpForm({
  gatedContentSlug,
  pageRoles,
  pageTitle,
  redirectTo,
  setFormState,
  mixpanelSource,
  submitButtonText,
  gatedContentOptInLabel,
  orgUUID,
}: {
  gatedContentSlug?: string
  pageRoles?: string[]
  pageTitle: string
  redirectTo: string
  setFormState: Dispatch<SetStateAction<AuthenticationFormState>>
  mixpanelSource: MixpanelUserJourneyType
  submitButtonText?: string
  gatedContentOptInLabel?: string
  orgUUID?: string
}) {
  const version = useExperimentVersion()
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: {errors, isDirty},
  } = useForm<SignUpFormControl>()
  const roleSelection = watch('role')
  const postGatedContent = useMutation<
    SubmitGatedContentData,
    SubmitGatedContentError,
    SubmitGatedContentVariables
  >((data) => submitGatedContent(data), {
    onSuccess: async () => {
      await trackCompletedSignUp({
        gatedContentSlug,
        mixpanelOpts: {mixpanelSource, version},
        roles: pageRoles || [],
        title: pageTitle,
      })
      setFormState('success')

      // scroll gated content form into view
      if (gatedContentSlug) {
        scrollToElement(`gated-content__${gatedContentSlug}`)
      }
    },
  })
  const buttonText = submitButtonText ?? 'Sign Up'

  const onSubmit: SubmitHandler<SignUpFormControl> = (data) => {
    const mixpanelDistinctId = mixpanel.get_distinct_id()
    const utms = getUTMsFromCookie()

    const requestBody = {
      redirect_to: redirectTo,
      country: data.country.value || 'US',
      email: data.email,
      full_name: data.full_name,
      graduation_year: data.graduation_year,
      is_practice_owner: data.is_practice_owner || false,
      role: data.role,
      specialties: data.specialties || [],
      gated_content_slug: gatedContentSlug || '',
      page_name: pageTitle,
      page_url: window.location.href,
      opted_in: data.opted_in,
      org_uuid: orgUUID,
      mixpanel_data: {
        mixpanel_distinct_id: mixpanelDistinctId,
        Journey: mixpanelSource,
        Title: getDocumentTitle(),
        Platform: MIXPANEL_PLATFORM,
        utms,
      },
    }
    postGatedContent.mutate(requestBody)
  }

  useEffect(() => {
    if (isDirty) {
      trackStartedSignUp({
        gatedContentSlug,
        mixpanelOpts: {version, mixpanelSource},
      })
    }
  }, [isDirty, gatedContentSlug, mixpanelSource, version])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column">
        {/* Name */}
        <div className="mb-3">
          <label htmlFor="fullName" className="form-label mb-0">
            Full Name<span className="text-danger">*</span>
          </label>
          <FormError name="full_name" errors={errors} />
          <input
            id="fullName"
            className="form-control"
            {...register('full_name', {
              required: 'Full name is required.',
            })}
          />
        </div>

        {/* Email */}
        <div className="mb-3">
          <label htmlFor="email" className="form-label mb-0">
            Email<span className="text-danger">*</span>
          </label>
          <FormError name="email" errors={errors} />
          <input
            id="email"
            className="form-control"
            {...register('email', {
              required: 'Email address is required.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please enter a valid email address.',
              },
            })}
          />
        </div>

        {/* Country */}
        <div className="mb-3">
          <label htmlFor="country" className="form-label mb-0">
            Country<span className="text-danger">*</span>
          </label>
          <FormError name="country" errors={errors} />
          <CountriesSelectInput inputName="country" control={control} />
        </div>

        {/* Role */}
        <div className="mb-3">
          <label htmlFor="roles" className="form-label mb-0">
            What is your job title or function? If you are a student, select the
            role you are studying for.<span className="text-danger">*</span>
          </label>
          <FormError name="role" errors={errors} />
          <RolesSelectInput control={control} />
        </div>

        {/* Graduation year */}
        {(roleSelection === HS_ROLE_OPTOMETRIST ||
          roleSelection === HS_ROLE_OPHTHALMOLOGIST) && (
          <div className="mb-3">
            <GraduationYearInput
              control={control}
              role={roleSelection}
              required={
                roleSelection === HS_ROLE_OPTOMETRIST ||
                roleSelection === HS_ROLE_OPHTHALMOLOGIST
              }
              errors={errors}
            />
          </div>
        )}

        {/* Specialties */}
        {(roleSelection === HS_ROLE_OPTOMETRIST ||
          roleSelection === HS_ROLE_OPHTHALMOLOGIST) && (
          <div className="mb-3">
            <SpecialtiesSelectInput
              role={roleSelection}
              control={control}
              required={
                roleSelection === HS_ROLE_OPTOMETRIST ||
                roleSelection === HS_ROLE_OPHTHALMOLOGIST
              }
              errors={errors}
            />
          </div>
        )}

        {/* Practice Owner? */}
        {roleSelection !== HS_ROLE_CONSUMER && (
          <div className="mb-3">
            <label htmlFor="is_practice_owner" className="form-label mb-0">
              Are you a practice owner?
            </label>{' '}
            <FormError name="is_practice_owner" errors={errors} />
            <BooleanSelectInput
              control={control}
              inputName="is_practice_owner"
            />
          </div>
        )}
        {gatedContentOptInLabel && (
          <div className="mb-3">
            <FormError name="opted_in" errors={errors} />
            <CheckboxInput
              control={control}
              inputName="opted_in"
              label={gatedContentOptInLabel}
            />
          </div>
        )}
        {/* Submit */}
        <div
          className={`mt-2 d-flex flex-column flex-lg-row align-items-center gap-4 ${version === 'B' ? 'justify-content-start' : 'justify-content-center mb-4'}`}
        >
          <button
            className={`btn btn-lg ${version === 'B' ? 'btn-green' : 'btn-primary'}`}
            type="submit"
            disabled={postGatedContent.isLoading}
          >
            {buttonText}
          </button>
          {version === 'B' && (
            <div className="d-flex flex-column align-items-center align-items-lg-start gap-2">
              <div>
                Already a subscriber?{' '}
                <span
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (gatedContentSlug) {
                        scrollToElement(`gated-content__${gatedContentSlug}`)
                      }
                      setFormState('signIn')
                    }
                  }}
                  role="button"
                  className="fw-bold text-decoration-underline"
                  onClick={() => {
                    if (gatedContentSlug) {
                      scrollToElement(`gated-content__${gatedContentSlug}`)
                    }
                    setFormState('signIn')
                  }}
                >
                  Sign in now!
                </span>
              </div>
              <div className="text-xs text-muted">
                By submitting this form, you are agreeing to our{' '}
                <a
                  href="https://jobs.eyesoneyecare.com/terms-of-service/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://jobs.eyesoneyecare.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

import React, {useContext} from 'react'

import {scrollToElement} from '../../../core/utils'
import {ResourceComponentContext} from '../ResourceComponent'

const AccessContentButton: React.FC = (props) => {
  const {resource} = useContext(ResourceComponentContext)
  return (
    <button
      onClick={() => scrollToElement(`gated-content__${resource.slug}`)}
      {...props}
    >
      Access Content
    </button>
  )
}
export default AccessContentButton

import Link from 'next/link'

import {getResourceSlug} from '../components/resources/utils'
import {getImageSource} from './sanity'

export function removeFalsy(obj) {
  const newObj = {}
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop]
    }
  })
  return newObj
}

/**
 * Returns whether the entire element is contained in the viewport.
 * @param element
 * @returns {boolean}
 */
export function isInViewport(element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Returns whether the entire element is visible in the viewport.
 * @param element
 * @returns {boolean}
 */
export function isElementVisible(element) {
  if (!element) return false
  const elementRect = element.getBoundingClientRect()
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight

  return elementRect.top <= windowHeight
}

/**
 * Returns whether any part of the element is visible in the viewport
 * @param element
 * @returns {boolean}
 */
export function isPartiallyInViewport(element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  const topIsBelowViewport = rect.top > window.innerHeight
  const bottomIsAboveViewport = rect.bottom < 0
  return !topIsBelowViewport && !bottomIsAboveViewport
}

export function titleCase(str) {
  if (!str) return str
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export function displayAuthors(authors) {
  if (!authors) {
    return ''
  }
  if (authors.length > 3) {
    return [...authors.slice(0, 3), 'et al.'].join(', ')
  }
  return authors.join(', ')
}

export function displayAuthorLinks(authors) {
  if (!authors) {
    return
  }
  const authorLinks = authors.map((author, i) => (
    <Link href={`/authors/${author.slug}`} key={i}>
      {author.name}
      {i !== authors.length - 1 && ', '}
    </Link>
  ))
  if (authors.length > 3) {
    return [...authorLinks.slice(0, 3), 'et al.']
  }
  return authorLinks
}

export function displayPublishedDate(datetime, monthLength = 'long') {
  // Date(null) will give epoch - return null so we don't see 1969
  if (!datetime) return null
  return new Date(datetime).toLocaleDateString('en-US', {
    year: 'numeric',
    month: monthLength, // 'short' or 'long'
    day: 'numeric',
  })
}

export function getFormattedImage(sanityImg, width) {
  try {
    return sanityImg
      ? getImageSource(sanityImg)
          .width(width)
          .quality(100)
          .fit('fill')
          .auto('format')
          .url()
      : '/logos/eoe-logo-card-default.png'
  } catch {
    return '/logos/eoe-logo-card-default.png'
  }
}

export function displayHitCount(hits) {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(hits)
}

/**
 * Linkify a react component or JSX
 * @param {JSX.Element} jsx - The JSX or component to wrap with a link
 * @param {string} link - The URL to link to
 * @param config
 * @returns
 */
export function linkify(jsx, link, config = {newTab: false}) {
  if (!link) return jsx
  return (
    <Link href={link} target={config.newTab ? '_blank' : '_self'}>
      {jsx}
    </Link>
  )
}

// Stolen from here to turn a string into a slug
// https://www.30secondsofcode.org/js/s/slugify
export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

export function getGenericSlug(type, slug, dateSlug) {
  if (!type) return slug

  switch (type) {
    case 'resourcePage':
      return getResourceSlug(slug)
    case 'story':
      return process.env.NODE_ENV === 'development'
        ? `http://localhost:3001${getStorySlug(dateSlug, slug)}`
        : `https://glance.eyesoneyecare.com${getStorySlug(dateSlug, slug)}`
    default:
      return slug
  }
}

export function getStorySlug(dateSlug, slug) {
  return `/stories/${dateSlug}/${slug}/`
}

export function scaleDimensions(width, height, maxWidth) {
  // Calculate the aspect ratio
  const aspectRatio = height / width

  // Scale the width to the maximum width
  let scaledWidth = maxWidth
  let scaledHeight = scaledWidth * aspectRatio

  return [scaledWidth, scaledHeight]
}
/**
 * Update an existing localStorage object with a new object.
 */
export function updateLocalStorageObject(key, newObj) {
  const existingObj = localStorage.getItem(key)
  const obj = existingObj ? JSON.parse(existingObj) : {}
  const updatedObj = JSON.stringify({...obj, ...newObj})
  localStorage.setItem(key, updatedObj)
}

export function scrollToElement(id) {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({behavior: 'smooth', block: 'start'})
  }
}

import {useContext} from 'react'

import Image from 'next/legacy/image'

import {getFormattedImage} from '../../../core/utils'
import {trackSponsorClick} from '../../analytics/tracking/trackSponsorClick'
import {CustomSponsors as CustomSponsorsType} from '../../organizations/schemas'
import {ResourceComponentContext} from '../ResourceComponent'

interface CustomSponsorsProps {
  name: string
  sponsors: CustomSponsorsType
}

export default function CustomSponsors({name, sponsors}: CustomSponsorsProps) {
  const {resource} = useContext(ResourceComponentContext)

  return (
    <div className="my-4 py-3 border-top border-bottom">
      <div className="d-flex align-items-center justify-content-center mb-2">
        <span className="sans-serif rounded-0 m-0 p-0 text-gray-500 text-center text-small">
          💙 {name}
        </span>
      </div>
      <div className="row align-items-center justify-content-center gy-2">
        {sponsors.map((sponsor) => (
          <div className="col-4" key={sponsor.name}>
            <a
              href={sponsor.website}
              target="_blank"
              className="pt-2"
              rel="noreferrer noopener"
              onClick={trackSponsorClick({
                resourceSlug: resource.slug,
                resourceTitle: resource.title,
                sponsor,
                source: `Resource Sidebar - ${name}`,
              })}
            >
              <Image
                alt={sponsor.name}
                src={getFormattedImage(sponsor.image?.asset)}
                layout="responsive"
                width="100"
                height="50"
                objectFit="contain"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

import Image from 'next/legacy/image'
import Link from 'next/link'

import {z} from 'zod'

import {SanityImage as SanityImageSchema} from '../../core/schemas'
import SanityImage from '../sanity/SanityImage'
import FieldDropdown from './dropdowns/FieldDropdown'

const IndexFieldPropsSchema = z.object({
  customNavLogo: SanityImageSchema.optional(),
  field: z.string(),
  overrideNavLogo: z.boolean().optional(),
})

export default function IndexField({
  customNavLogo,
  field,
  overrideNavLogo,
}: z.infer<typeof IndexFieldPropsSchema>) {
  const maxLogoHeight = 35
  const logoWidth = 126
  const aspectRatio = customNavLogo?.asset.metadata.dimensions.aspectRatio

  let customLogoWidth = logoWidth

  if (customNavLogo && aspectRatio) {
    if (aspectRatio > 1) {
      customLogoWidth = maxLogoHeight * aspectRatio
    } else {
      customLogoWidth = maxLogoHeight / aspectRatio
    }
  }

  return field ? (
    <div className="d-flex align-items-center justify-content-center">
      {overrideNavLogo === true && customNavLogo ? (
        <SanityImage
          alt={`${field || ''} logo`}
          width={Math.round(customLogoWidth)}
          height={maxLogoHeight}
          objectFit="contain"
          src={customNavLogo}
        />
      ) : (
        <>
          <Link href="/" className="navbar-brand p-0 no-hover-underline">
            <Image
              alt="Eyes On Eyecare logo"
              src="/logos/eoe-logo.png"
              width={logoWidth}
              height={maxLogoHeight}
              objectFit="contain"
              className="border-end pe-3"
            />
          </Link>
          {customNavLogo ? (
            <SanityImage
              alt={`${field || ''} logo`}
              width={Math.round(customLogoWidth)}
              height={maxLogoHeight}
              objectFit="contain"
              src={customNavLogo}
            />
          ) : (
            <FieldDropdown currentField={field} />
          )}
        </>
      )}
    </div>
  ) : (
    <>
      <div className="d-flex align-items-center">
        <Link href="/optometry/" className="text-small me-3">
          OPTOMETRY
        </Link>
        <Link href="/ophthalmology/" className="text-small">
          OPHTHALMOLOGY
        </Link>
      </div>
      <Link href="/" className="navbar-brand p-0 no-hover-underline">
        <Image
          alt="Eyes On Eyecare logo"
          src="/logos/eoe-logo.png"
          width="126"
          height="35"
          objectFit="contain"
        />
      </Link>
    </>
  )
}

import {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import {faClose, faCopy} from '@fortawesome/pro-light-svg-icons'
import {faLink} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {trackUserEngagement} from '../analytics/tracking/trackUserEngagement'
import {getSlugFromUrl} from '../resources/utils'

interface CiteButtonProps {
  title: string
  url: string
  publishedDate: Date
  authors: string
}

export function CiteButton({
  title,
  url,
  publishedDate,
  authors,
}: CiteButtonProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [citationStyle, setCitationStyle] = useState('apa')

  function getMLACitation(
    title: string,
    siteTitle: string,
    author: string,
    url: string,
    publishedDate: Date,
  ) {
    // Format the date as "Day Month Year"
    const formattedPublishedDate = `${publishedDate.getDate()} ${publishedDate.toLocaleString(
      'en-US',
      {month: 'short'},
    )} ${publishedDate.getFullYear()}`

    // Return the formatted citation
    return (
      <span>
        {author}. &quot;{title}&quot; <em>{siteTitle}</em>,{' '}
        {formattedPublishedDate}, {url}
      </span>
    )
  }

  function getAPACitation(
    title: string,
    siteTitle: string,
    author: string,
    url: string,
    publishedDate: Date,
  ) {
    // Format the date as "Year, Month Day"
    const formattedPublishedDate = `${publishedDate.getFullYear()}, ${publishedDate.toLocaleString(
      'en-US',
      {month: 'short'},
    )} ${publishedDate.getDate()}`

    // Return the formatted citation
    return (
      <span>
        {author} ({formattedPublishedDate}). <em>{title}</em>. {siteTitle}.{' '}
        {url}
      </span>
    )
  }

  function getChicagoCitation(
    title: string,
    siteTitle: string,
    author: string,
    url: string,
    publishedDate: Date,
  ) {
    // Format the date as "Month Day, Year"
    const formattedPublishedDate = `${publishedDate.toLocaleString('en-US', {
      month: 'long',
    })} ${publishedDate.getDate()}, ${publishedDate.getFullYear()}`

    // Return the formatted citation
    return (
      <span>
        {author}, &quot;{title}.&quot; <em>{siteTitle}</em>,{' '}
        {formattedPublishedDate}. {url}
      </span>
    )
  }

  function getFormattedCitation(style: string) {
    // The title, author, URL, and date of the document
    const siteTitle = 'Eyes On Eyecare'

    // Use the selected citation style to format the citation
    switch (style) {
      case 'apa':
        return getAPACitation(title, siteTitle, authors, url, publishedDate)
      case 'mla':
        return getMLACitation(title, siteTitle, authors, url, publishedDate)
      case 'chi':
        return getChicagoCitation(title, siteTitle, authors, url, publishedDate)
      default:
        return ''
    }
  }

  function copyCitationToClipboard() {
    // Get the text content of the element
    const element = document.getElementById('citation')
    const text = element?.textContent || ''
    // Create a temporary textarea element
    const textarea = document.createElement('textarea')
    // Set the textarea's value to the text content of the element
    textarea.value = text
    // Append the textarea to the document
    document.body.appendChild(textarea)
    // Select the text in the textarea
    textarea.select()
    // Copy the selected text to the clipboard
    document.execCommand('copy')
    // Remove the textarea from the document
    document.body.removeChild(textarea)
    setShowMessage(true)
    setShowMessage(true)
    trackUserEngagement({
      action: 'Cite',
      documentType: 'Resource',
      slug: getSlugFromUrl(url),
    })
  }

  useEffect(() => {
    // Set a timeout to hide the message after 5 seconds
    if (showMessage) {
      const timeoutId = setTimeout(() => setShowMessage(false), 3000)
      // Return a cleanup function that clears the timeout
      return () => clearTimeout(timeoutId)
    }
  }, [showMessage])

  return (
    <>
      <button
        className={
          'btn btn-outline-primary d-flex me-2 justify-content-center align-items-center py-3 py-sm-1 flex-grow-1 flex-lg-grow-0'
        }
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon icon={faLink} className="me-0 me-sm-2" size="lg" />
        <span className="d-none d-sm-block">Cite</span>
      </button>
      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header className="d-flex align-items-center justify-content-between w-100">
          <Modal.Title>
            <span className="fs-6">
              <FontAwesomeIcon icon={faLink} className="me-2" />
              Cite this story
            </span>
          </Modal.Title>
          <button className="btn btn-link" onClick={() => setIsOpen(false)}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="small">Select Citation Style:</label>
            <select
              className="form-control"
              value={citationStyle}
              onChange={(e) => setCitationStyle(e.target.value)}
            >
              <option value="apa">APA</option>
              <option value="mla">MLA</option>
              <option value="chi">Chicago Manual of Style</option>
            </select>
          </div>
          <p className="alert alert-secondary" id="citation">
            {getFormattedCitation(citationStyle)}
          </p>
        </Modal.Body>
        <Modal.Footer>
          {showMessage && (
            <div>
              <small className="text-success">Copied to clipboard!</small>
            </div>
          )}
          <Button variant="primary" onClick={copyCitationToClipboard}>
            <FontAwesomeIcon icon={faCopy} className="me-2" />
            Copy Citation
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import {useContext} from 'react'

import Image from 'next/legacy/image'

import {trackSponsorClick} from '../../analytics/tracking/trackSponsorClick'
import {ResourceComponentContext} from '../ResourceComponent'

const EOESponsors = [
  {
    name: 'Astellas Pharma',
    website:
      'https://izervayecp.com?utm_medium=display&utm_source=9578469&utm_campaign=32192942&utm_content=custom&utm_term=398179696',
    logo: '/logos/companies/astellas-logo.png',
    imageWidth: 110,
    imageHeight: 30,
  },
  {
    name: 'Optilight by Lumenis',
    website:
      'https://information.lumenis.com/optilight?utm_source=eoe&utm_medium=spc&utm_campaign=b2b_be_the_doctor_eoe_us&utm_content=logo_adv',
    logo: '/logos/companies/optilight-by-lumenis-logo-updated.png',
    imageWidth: 139,
    imageHeight: 30,
  },
]

export default function Sponsors() {
  const {resource} = useContext(ResourceComponentContext)

  return (
    <div className="my-4 py-3 border-top border-bottom">
      <div className="d-flex align-items-center justify-content-center mb-2">
        <span className="sans-serif rounded-0 m-0 p-0 text-gray-500 text-center text-small">
          Eyes On Eyecare Site Sponsors
        </span>
      </div>
      <div className="d-flex justify-content-around align-items-center">
        {EOESponsors.map((sponsor) => (
          <a
            key={sponsor.name}
            href={sponsor.website}
            className="pt-2"
            target="_blank"
            rel="noreferrer noopener"
            onClick={trackSponsorClick({
              resourceSlug: resource.slug,
              resourceTitle: resource.title,
              sponsor: {
                name: sponsor.name,
                website: sponsor.website,
              },
              source: `Resource Sidebar - Eyes On Eyecare Site Sponsors`,
            })}
          >
            <Image
              src={sponsor.logo}
              alt="Astellas Logo"
              layout="intrinsic"
              width={sponsor.imageWidth}
              height={sponsor.imageHeight}
            />
          </a>
        ))}
      </div>
    </div>
  )
}

import {useContext} from 'react'

import {useExperimentVersion} from '../../core/hooks/useExperimentVersion'
import styles from '../../styles/modules/GatedContent.module.scss'
import {ResourceComponentContext} from '../resources/ResourceComponent'
import {GatedContent as GatedContentType} from '../resources/schemas'
import {GatedContentA} from './GatedContentA'
import {GatedContentB} from './GatedContentB'

export const GatedContentController = ({
  gatedContent,
}: {
  gatedContent: GatedContentType
}) => {
  const {resource} = useContext(ResourceComponentContext)
  const version = useExperimentVersion()

  return (
    <div
      id={`gated-content__${resource.slug}`}
      className={`my-4 ${resource.gatedContentOuterHeading ? styles.scrollMarginSmall : styles.scrollMarginLarge}`}
    >
      {version === 'B' ? (
        <GatedContentB gatedContent={gatedContent} />
      ) : (
        <GatedContentA gatedContent={gatedContent} />
      )}
    </div>
  )
}

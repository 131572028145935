import Image from 'next/legacy/image'
import Link from 'next/link'

import {faDownload} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {CC_ADDRESS, CC_PHONE_NUMBER} from '../../core/constants'
import {trackSponsorClick} from '../analytics/tracking/trackSponsorClick'
import SocialLinkComponent from '../authors/SocialLinkComponent'

export default function DefaultFooter({pageType}) {
  const currentYear = new Date().getFullYear()
  return (
    <footer className={`bg-black${pageType === 'index' ? '' : ' mt-lg-5'}`}>
      <div className="p-4 p-lg-5" id="eoFooter">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className="mb-3">
                <Link href="/" className="p-0 m-0">
                  <Image
                    alt="Eyes On Eyecare logo"
                    src="/logos/eoe-logo.png"
                    width="113"
                    height="36"
                    className="p-0 m-0"
                    objectFit="contain"
                  />
                </Link>
                <span>{CC_ADDRESS}</span>
                <span>{CC_PHONE_NUMBER}</span>
              </div>
              <div className="mb-3" id="eo_footer__sponsors_container">
                <span>
                  Thank you to the Eyes On Eyecare Site Sponsors,{' '}
                  <a
                    href="https://izervayecp.com?utm_medium=display&utm_source=9578469&utm_campaign=32192942&utm_content=custom&utm_term=398179696"
                    className="text-decoration-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={trackSponsorClick({
                      sponsor: {
                        name: 'Astellas Pharma',
                        website:
                          'https://izervayecp.com?utm_medium=display&utm_source=9578469&utm_campaign=32192942&utm_content=custom&utm_term=398179696',
                      },
                      source: 'EOE Footer',
                    })}
                  >
                    Astellas
                  </a>{' '}
                  and{' '}
                  <a
                    className="text-decoration-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://information.lumenis.com/optilight?utm_source=eoe&utm_medium=spc&utm_campaign=b2b_be_the_doctor_eoe_us&utm_content=footer_adv"
                    onClick={trackSponsorClick({
                      sponsor: {
                        name: 'Lumenis',
                        website:
                          'https://information.lumenis.com/optilight?utm_source=eoe&utm_medium=spc&utm_campaign=b2b_be_the_doctor_eoe_us&utm_content=footer_adv',
                      },
                      source: 'EOE Footer',
                    })}
                  >
                    Lumenis
                  </a>
                  .
                </span>
              </div>
              <div className="">
                <span>
                  Read our reviews on{' '}
                  <a
                    href="https://goo.gl/maps/7JEE7Z85ADr"
                    className="text-decoration-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google
                  </a>
                </span>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="d-flex flex-column align-items-between">
                <div className="mb-3">
                  <span className="fw-bolder text-dark mb-1 serif">
                    Hiring Services
                  </span>
                  <span>
                    <a href="https://jobs.eyesoneyecare.com/jobs/eyecare/">
                      Search for Jobs
                    </a>
                  </span>
                  <span>
                    <a href="https://jobs.eyesoneyecare.com/eyecare-hiring-products/">
                      Hiring Services for Employers
                    </a>
                  </span>
                  <span>
                    <a href="https://jobs.eyesoneyecare.com/post-eyecare-jobs/">
                      Post an Eyecare Job
                    </a>
                  </span>
                  <span>
                    <a href="https://jobs.eyesoneyecare.com/eyecare-recruiting/">
                      Eyecare Recruiting
                    </a>
                  </span>
                  <span>
                    <a href="https://jobs.eyesoneyecare.com/pricing/">
                      Pricing
                    </a>
                  </span>
                  <span>
                    <a href="https://jobs.eyesoneyecare.com/jobs/all/">
                      Job Sitemap
                    </a>
                  </span>
                </div>
                <div>
                  <span className="fw-bolder text-dark mb-1 serif">
                    Learn More
                  </span>
                  <span>
                    <a
                      href="https://about.eyesoneyecare.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      About Us
                    </a>
                  </span>
                  <span>
                    <Link href="/contact/">Contact Us</Link>
                  </span>
                  <span>
                    {/* disabled due to algolia router compat issues */}
                    {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                    <a href="https://glance.eyesoneyecare.com/stories/?organizations=Eyes+On+Eyecare">
                      Newsroom
                    </a>
                  </span>
                  <span>
                    <Link href="/editorial-policy/">Editorial Policy</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mt-3 mt-lg-0">
              <div className="mb-3">
                <span className="fw-bolder text-dark mb-1 serif">
                  Clinical Articles
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Ocular+Surface">
                    Ocular Surface
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Contact+Lens">
                    Contact Lens
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Glaucoma">Glaucoma</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Retina">Retina</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Neuro">Neuro</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?clinical_specialty=Low+Vision">
                    Low Vision
                  </a>
                </span>
              </div>
              <div>
                <span className="fw-bolder text-dark mb-1 serif">
                  Join The Team
                </span>
                <span>
                  <a
                    href="https://covalentcreative.com/team"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Careers
                  </a>
                </span>
                <span>
                  <Link href="/editorial-board/">Editorial Board</Link>
                </span>
                <span>
                  <a
                    href="https://jobs.eyesoneyecare.com/go/write-for-us/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Become a Writer
                  </a>
                </span>
              </div>
            </div>
            <div className="col-6 col-lg-3 mt-3 mt-lg-0">
              <div>
                <span className="fw-bolder text-dark mb-1 serif">
                  Career Articles
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Finance">Personal Finance</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Practice+Management">
                    Practice Management
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Optometry+Students">
                    Optometry Students
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=New+Graduate">New Graduate</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Residency">Residency</a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/resources/?category=Salary%20%26%20Benefits">
                    Salary &amp; Benefits
                  </a>
                </span>
              </div>
              <div className="mt-3">
                <span className="fw-bolder text-dark mb-1 serif">Events</span>
                <span>
                  <a
                    href="https://events.eyesoneyecare.com/#events"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Event Schedule
                  </a>
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-4">
            <div className="col-lg-6">
              <h6>Advertising, Media Kit, &amp; Partnerships</h6>
              <div className="d-flex flex-column flex-lg-row align-items-center">
                <div className="me-lg-3">
                  <p className="text-small m-0">
                    Eyes On Eyecare is currently distributing our 2024 media kit
                    and Eyes On event prospectuses. Contact us to learn more
                    about available opportunities - spaces are limited.
                  </p>
                </div>
                <div className="w-100 mt-3 mt-lg-0">
                  <a
                    className="btn text-small btn-sm btn-primary text-white mb-lg-2 me-2 me-lg-0"
                    href="https://about.eyesoneyecare.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    Media Kit
                  </a>
                  <a
                    className="btn text-small btn-sm btn-primary text-white"
                    href="https://sponsorship.eyesoneyecare.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    Event Prospectus
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <h6>We&apos;re Hiring!</h6>
              <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                <div className="me-lg-3">
                  <p className="text-small m-0">
                    Do you work in the eyecare industry? Check out our open
                    positions!
                  </p>
                </div>
                <div className="w-100 mt-3 mt-lg-0">
                  <a
                    className="btn text-small btn-sm btn-dark text-white"
                    href="https://covalentcreative.com/team"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Open Positions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="p-4 p-lg-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-start flex-column flex-lg-row">
                  <div className="d-flex align-items-center me-3">
                    <small className="me-2">Eyes On Eyecare:</small>
                    <SocialLinkComponent
                      link="https://www.linkedin.com/company/eyesoneyecare/"
                      className="text-black me-2"
                    />
                    <SocialLinkComponent
                      link="https://www.tiktok.com/@eyesoneyecare/"
                      className="text-black"
                    />
                  </div>
                  <div className="d-flex align-items-center me-3">
                    <small className="me-2">CovalentCreative:</small>
                    <SocialLinkComponent
                      link="https://www.linkedin.com/company/covalentcreative/"
                      className="text-black"
                    />
                  </div>
                  <div className="d-flex align-items-center me-3">
                    <small className="me-2">Optometry:</small>
                    <div className="d-flex">
                      <SocialLinkComponent
                        link="https://www.facebook.com/eyesoneyecare.optometry"
                        className="text-black me-2"
                      />
                      <SocialLinkComponent
                        link="https://www.instagram.com/eyesoneyecare.optometry/"
                        className="text-black me-2"
                      />
                      <SocialLinkComponent
                        link="https://twitter.com/eyeson_optom"
                        className="text-black"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center me-3">
                    <small className="me-2">Ophthalmology:</small>
                    <div className="d-flex">
                      <SocialLinkComponent
                        link="https://www.facebook.com/eyesoneyecare.ophthalmology"
                        className="text-black me-2"
                      />
                      <SocialLinkComponent
                        link="https://www.instagram.com/eyesoneyecare.ophthalmology/"
                        className="text-black me-2"
                      />
                      <SocialLinkComponent
                        link="https://twitter.com/eyeson_ophth"
                        className="text-black"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center me-3 ">
                    <small className="me-2">Glance:</small>
                    <SocialLinkComponent
                      link="https://www.instagram.com/2020glance"
                      className="text-black"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2" style={{paddingBottom: '6.5rem'}}>
        <div className="container">
          <div className="text-small text-white d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <span className="text-center mb-2 mb-lg-0">
              © {currentYear} Eyes On Eyecare®. All rights reserved.
            </span>
            <div>
              <a
                className="text-white me-3"
                href="https://jobs.eyesoneyecare.com/privacy/"
              >
                Privacy Policy
              </a>
              <a
                className="text-white"
                href="https://jobs.eyesoneyecare.com/terms-of-service/"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

import {trackFacebookCustomEvent} from '../facebook'
import {trackGoogleEvent} from '../google'
import {
  MIXPANEL_EVENT_STARTED_SIGN_UP_FORM,
  MIXPANEL_PLATFORM,
  MixpanelUserJourneyType,
  mixpanel,
  trackGatedContentConversion,
} from '../mixpanel'
import {getDocumentTitle} from '../utils'

interface TrackStartedSignUpProps {
  gatedContentSlug?: string
  mixpanelOpts: {
    version?: 'A' | 'B'
    mixpanelSource: MixpanelUserJourneyType
  }
}

/**
 * This function makes API calls to third party platforms for conversion tracking and analytics purposes.
 */
export function trackStartedSignUp({
  gatedContentSlug,
  mixpanelOpts,
}: TrackStartedSignUpProps) {
  const eventName = gatedContentSlug
    ? 'Started Gated Content Form'
    : 'Started Sign Up Form'
  const baseAnalyticsProps = {
    Journey: mixpanelOpts.mixpanelSource,
    Platform: MIXPANEL_PLATFORM,
    Title: getDocumentTitle(),
    'Resource Slug': gatedContentSlug,
  }

  mixpanel.track(MIXPANEL_EVENT_STARTED_SIGN_UP_FORM, baseAnalyticsProps)
  trackGoogleEvent('sign-up', {
    event_category: 'Sign Up',
    event_label: baseAnalyticsProps.Platform,
    page_location: baseAnalyticsProps.Journey,
    page_title: baseAnalyticsProps.Title,
  })
  trackFacebookCustomEvent(eventName, baseAnalyticsProps)

  if (gatedContentSlug && mixpanelOpts.version) {
    trackGatedContentConversion({
      slug: gatedContentSlug,
      step: 'Started',
      version: mixpanelOpts.version,
    })
  }
}

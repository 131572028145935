import {MIXPANEL_PLATFORM, mixpanel} from '../mixpanel'

interface TrackUserEngagementProps {
  action: 'Like' | 'Save' | 'Cite' | 'Share'
  documentType: 'Resource'
  slug: string
  subaction?: string
}

export function trackUserEngagement({
  action,
  documentType,
  slug,
  subaction,
}: TrackUserEngagementProps) {
  mixpanel.track('User Engagement', {
    Action: action,
    Subaction: subaction,
    'Document Type': documentType,
    Platform: MIXPANEL_PLATFORM,
    Slug: slug,
  })
}

import {useEffect, useState} from 'react'

import {
  ExperimentVersion,
  isExperimentVersionUnset,
  saveExperimentVersionToCookie,
} from '../../components/analytics/utils'

export const useExperimentVersion = () => {
  // Ensure version is initially set as undefined to avoid SSR issues
  const [version, setVersion] = useState<ExperimentVersion>()

  useEffect(() => {
    if (isExperimentVersionUnset(version)) {
      const newVersion = saveExperimentVersionToCookie()
      setVersion(newVersion)
    }
  }, [version])

  return version
}

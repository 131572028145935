import {useContext, useEffect, useMemo, useState} from 'react'

import Image from 'next/image'

import {PortableText} from '@portabletext/react'

import {useUser} from '../../core/auth'
import serializers from '../../core/gatedSerializers'
import styles from '../../styles/modules/GatedContent.module.scss'
import {ANALYTICS_ENABLED} from '../analytics/config'
import {MIXPANEL_JOURNEY_GATED_CONTENT_FORM} from '../analytics/mixpanel'
import {parselyTrackLeadCapture} from '../analytics/parsely'
import AuthenticationForm from '../forms/AuthenticationForm'
import {ResourceComponentContext} from '../resources/ResourceComponent'
import {GatedContent as GatedContentType} from '../resources/schemas'

export const GatedContentB = ({
  gatedContent,
}: {
  gatedContent: GatedContentType
}) => {
  const {resource: page, url} = useContext(ResourceComponentContext)
  const {user} = useUser()

  const gatedContentOuterHeading = page.gatedContentOuterHeading ?? ''
  const gatedContentHeading =
    page.gatedContentHeading ?? 'Keep reading for free!'
  const gatedContentSubHeading =
    page.gatedContentSubheading ??
    'Every article, ebook, and video is 100% free.'
  const [redirectUrl, setRedirectUrl] = useState<string>(url)

  // Set the current url on component mount and when the route changes
  useEffect(() => {
    let gatedUrl = url
    if (!gatedUrl.includes('#gated-content')) {
      gatedUrl = `${gatedUrl}#gated-content`
    }
    setRedirectUrl(gatedUrl)
  }, [url])

  const MemoizedFormWrapper = useMemo(
    () =>
      function FormWrapper({children}: {children: React.ReactNode}) {
        return (
          <div>
            <div
              className={`row pb-0 px-3 ${gatedContentOuterHeading ? styles.outerHeading : styles.noOuterHeading}`}
            >
              <h3
                className="ps-4 font-nunito fw-bold col-7"
                style={{hyphens: 'none'}}
              >
                {gatedContentOuterHeading}
              </h3>
              <div className="d-flex align-items-center justify-content-center position-relative">
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                  }}
                >
                  <Image
                    alt=""
                    src="/stock/od-pointing.png"
                    width={200}
                    height={200}
                    sizes="(max-width: 800px) 200px, 600px"
                    className={`p-0 m-0 ${gatedContentOuterHeading ? styles.odImage : styles.odImageNoHeading}`}
                    layout="intrinsic"
                  />
                </div>
              </div>
            </div>
            <div
              className="shadow position-relative rounded-xl"
              style={{backgroundColor: `rgb(245, 245, 245)`}}
            >
              <div id="gated-content" className="p-4 p-lg-5">
                {/* Gated Header */}
                <div id="download" className={`d-flex flex-column`}>
                  <h2 className="fs-1">{gatedContentHeading}</h2>
                  <div className="text-muted">{gatedContentSubHeading}</div>
                </div>
                <div className="mt-3">{children}</div>
                {!user && (
                  <div
                    className="d-none d-lg-block"
                    style={{paddingBottom: '64px'}}
                  >
                    <div
                      id="wave"
                      style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                      }}
                    >
                      <Image
                        alt=""
                        src="/stock/line-wave.png"
                        width={600}
                        height={200}
                        sizes="(max-width: 800px) 100vw, 600px"
                        style={{width: '100%', height: 'auto'}}
                        className="p-0 m-0"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      },
    [
      gatedContentOuterHeading,
      gatedContentHeading,
      gatedContentSubHeading,
      user,
    ],
  )

  if (user) {
    return (
      <MemoizedFormWrapper>
        <UnGatedContent gatedContent={gatedContent} />
      </MemoizedFormWrapper>
    )
  }

  return (
    <MemoizedFormWrapper>
      {/* Sign Up */}
      <AuthenticationForm
        initialState="signUp"
        submitButtonText="Unlock Content"
        redirectTo={redirectUrl}
        gatedContentSlug={page.slug}
        pageTitle={page.title}
        pageRoles={page.roles}
        gatedContentOptInLabel={page.gatedContentOptInLabel}
        mixpanelSource={MIXPANEL_JOURNEY_GATED_CONTENT_FORM}
        orgUUID={page.sponsor?.uuid || undefined}
      />
    </MemoizedFormWrapper>
  )
}

const UnGatedContent = ({gatedContent}: {gatedContent: GatedContentType}) => {
  const {user} = useUser()
  const firstName = user?.profile?.first_name

  // Use this function to run conversion tracking & analytics events.
  const handleClick = (redirectUrl?: string) => {
    if (ANALYTICS_ENABLED) {
      // Parsely
      parselyTrackLeadCapture('EOE - Access Gated Content')
    }
    window.open(redirectUrl)
  }

  let body
  if (gatedContent.type === 'redirect') {
    body = (
      <span
        className="btn btn-lg btn-primary"
        role="button"
        onClick={() => handleClick(gatedContent.redirectUrl)}
      >
        Access Resource
      </span>
    )
  } else {
    body = (
      <div className="p-3 w-100">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore: TODO: convert serializers to typescript */}
        <PortableText value={gatedContent.body} components={serializers} />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="lead mb-2">
        Good to see you again{firstName && `, ${firstName}`}!
      </p>
      {body}
    </div>
  )
}

import {useState} from 'react'

import Link from 'next/link'

import {z} from 'zod'

import {useUser} from '../../core/auth'
import {useExperimentVersion} from '../../core/hooks/useExperimentVersion'
import SignOut from '../SignOut'
import {MixpanelUserJourneySchema} from '../analytics/mixpanel'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'

const AuthenticationFormStateSchema = z.enum(['signUp', 'signIn', 'success'])
export type AuthenticationFormState = z.infer<
  typeof AuthenticationFormStateSchema
>

const AuthenticationFormPropsSchema = z.object({
  displayFormTitle: z.boolean().optional(),
  gatedContentSlug: z.string().optional(),
  initialState: AuthenticationFormStateSchema,
  pageRoles: z.array(z.string()).optional(),
  pageTitle: z.string(),
  redirectTo: z.string(),
  submitButtonText: z.string().optional(),
  successMessage: z.string().optional(),
  gatedContentOptInLabel: z.string().optional(),
  orgUUID: z.string().optional(),
  mixpanelSource: MixpanelUserJourneySchema,
})

const AuthenticationForm = ({
  displayFormTitle,
  gatedContentSlug,
  initialState,
  pageRoles,
  pageTitle,
  redirectTo,
  mixpanelSource,
  submitButtonText,
  successMessage,
  gatedContentOptInLabel,
  orgUUID,
}: z.infer<typeof AuthenticationFormPropsSchema>) => {
  const [formState, setFormState] =
    useState<AuthenticationFormState>(initialState)
  const {user, isLoading: isAuthenticating} = useUser()
  const version = useExperimentVersion()

  return (
    <div>
      {/* User is authenticated, show Sign Out component */}
      {user && !isAuthenticating && <SignOut />}

      {/* Conditionally display Sign Up Form */}
      {!user && !isAuthenticating && formState === 'signUp' && (
        <>
          {displayFormTitle && (
            <h3 className="mb-3 pb-3">Subscribe to Eyes On Eyecare</h3>
          )}
          <SignUpForm
            submitButtonText={submitButtonText}
            redirectTo={redirectTo}
            setFormState={setFormState}
            gatedContentSlug={gatedContentSlug}
            pageTitle={pageTitle}
            pageRoles={pageRoles}
            gatedContentOptInLabel={gatedContentOptInLabel}
            orgUUID={orgUUID}
            mixpanelSource={mixpanelSource}
          />
        </>
      )}

      {/* Conditionally display Sign In Form */}
      {!user && !isAuthenticating && formState === 'signIn' && (
        <>
          {displayFormTitle && (
            <h3 className="mb-3 pb-3">Sign in to your account</h3>
          )}
          <SignInForm
            mixpanelSource={mixpanelSource}
            submitButtonText={submitButtonText}
            redirectTo={redirectTo}
            setFormState={setFormState}
          />
        </>
      )}

      {/* Conditionally display Success */}
      {!user && !isAuthenticating && formState === 'success' && (
        <div
          className={`text-success lead ${version === 'B' ? 'text-start' : 'text-center'}`}
        >
          {successMessage ??
            'Almost there! Check your email and click the link to get your content!'}
        </div>
      )}

      {/* Acknowledgements/TOS */}
      {formState !== 'success' && !user && !isAuthenticating && (
        <div
          className={`d-flex ${version === 'B' ? 'justify-content-start mb-2' : 'justify-content-center'}`}
        >
          {formState === 'signIn' && (
            <div>
              Not a subscriber?{' '}
              <span
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setFormState('signUp')
                  }
                }}
                role="button"
                className="fw-bold text-decoration-underline"
                onClick={() => setFormState('signUp')}
              >
                Sign up now!
              </span>
            </div>
          )}
          {formState === 'signUp' && version !== 'B' && (
            <div className="d-flex flex-column align-items-center">
              <div className="mb-3">
                Already a subscriber?{' '}
                <span
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setFormState('signIn')
                    }
                  }}
                  role="button"
                  className="fw-bold text-decoration-underline"
                  onClick={() => setFormState('signIn')}
                >
                  Sign in now!
                </span>
              </div>
              <div className="text-xs text-muted">
                By submitting this form, you are agreeing to our{' '}
                <Link href="https://jobs.eyesoneyecare.com/terms-of-service/">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link href="https://jobs.eyesoneyecare.com/privacy/">
                  Privacy Policy
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default AuthenticationForm

import {z} from 'zod'

import {SanityBlock, SanityImage, StickyFooterSchema} from '../../core/schemas'
import {CustomSponsorSchema} from '../organizations/schemas'

export const HeroProjectionSchema = z.object({
  featuredImage: SanityImage,
  title: z.string(),
  featuredText: z.array(SanityBlock),
  url: z.string(),
  isVideo: z.boolean(),
})
export type HeroProjection = z.infer<typeof HeroProjectionSchema>

export const ResourceProjectionSchema = z.object({
  _createdAt: z.string(),
  _id: z.string(),
  _rev: z.string(),
  _type: z.literal('resourcePage'),
  _updatedAt: z.string(),
  authors: z.array(
    z.object({
      _id: z.string(),
      bio: z.array(SanityBlock),
      image: SanityImage,
      name: z.string(),
      slug: z.string(),
      socialLinks: z.array(z.string()),
    }),
  ),
  body: z.array(SanityBlock),
  category: z.string(),
  citations: z.array(SanityBlock),
  clinicalSpecialty: z.string(),
  containsGatedContent: z.string(),
  containsLottie: z.string(),
  customAd: z
    .object({
      name: z.string(),
      headerCode: z.string(),
      ctaWidgetCode: z.string(),
      inReadCode: z.string(),
      sidebarCode: z.string(),
      stickyFooterCode: z.string(),
    })
    .nullish(),
  customAdsEndDateTime: z.string().nullish(),
  customSponsors: z
    .object({
      name: z.string(),
      sponsors: z.array(CustomSponsorSchema),
    })
    .nullish(),
  disclosure: z.string(),
  disclosureOverride: z.array(SanityBlock).nullish(),
  enableInfiniteScroll: z.boolean(),
  enableTextToSpeech: z.boolean(),
  estimatedReadTime: z.number(),
  featuredImage: SanityImage.extend({
    dimensions: z.object({
      aspectRatio: z.number(),
      height: z.number(),
      width: z.number(),
    }),
  }),
  featuredText: z.array(SanityBlock),
  gatedContentOuterHeading: z.string().optional(),
  gatedContentEmoji: z.string().nullish(),
  gatedContentHeading: z.string(),
  gatedContentShowSubscriberCount: z.boolean(),
  gatedContentSubheading: z.string(),
  gatedContentOptInLabel: z.string().optional(),
  inReadAdIteration: z.number(),
  includeCampaignTracker: z.boolean(),
  mediaType: z.string(),
  npiqClinicalSpecialty: z.string(),
  publishedAt: z.string(),
  roles: z.array(z.string()),
  showAds: z.boolean(),
  showETR: z.boolean(),
  showFeaturedImage: z.boolean(),
  showInReadAds: z.boolean(),
  showSocialButtons: z.boolean(),
  showSponsorsBlock: z.boolean(),
  // TODO: migrate existing documents to set `showCTAWidget` to `false` instead
  // of null. Old documents have `null` instead of a boolean value.
  showCTAWidget: z.boolean().nullable(),
  slug: z.string(),
  stickyFooter: StickyFooterSchema.nullish(),
  sponsor: z
    .object({
      uuid: z.string().nullish(),
      name: z.string(),
      slug: z.string(),
    })
    .nullish(),
  tags: z.array(z.string()).optional(),
  templateType: z.string(),
  title: z.string(),
  titleOverride: z.array(SanityBlock).optional(),
  infiniteScrollSlugs: z.array(z.string()).nullable(),
  useCustomAds: z.boolean(),
})
export type ResourceProjection = z.infer<typeof ResourceProjectionSchema>

export const DocumentCardSchema = z.object({
  _id: z.string(),
  _type: z.enum(['resourcePage', 'story']),
  featuredImage: SanityImage.nullish(),
  resourceCategory: z.any().nullish(),
  storyCategory: z.any().nullish(),
  title: z.string(),
  titleOverride: z.array(SanityBlock).nullish(),
  publishedAt: z.string(),
  dateSlug: z.string().nullish(),
  estimatedReadTime: z.number().nullish(),
  slug: z.string(),
  authors: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .nullish(),
})
export const DocumentCardsSchema = z.array(DocumentCardSchema)
export type DocumentCard = z.infer<typeof DocumentCardSchema>

export const BasicResourceProjectionSchema = z.object({
  _id: z.string(),
  _type: z.literal('resourcePage'),
  authors: z.array(
    z.object({
      name: z.string(),
      slug: z.string(),
    }),
  ),
  category: z.string(),
  featuredImage: SanityImage,
  featuredText: z.array(SanityBlock),
  publishedAt: z.string(),
  slug: z.string(),
  sponsor: z
    .object({
      name: z.string(),
      uuid: z.string(),
    })
    .optional(),
  tags: z.array(z.string()).optional(),
  title: z.string(),
  titleOverride: z.array(SanityBlock).optional(),
  estimatedReadTime: z.string().nullish(),
})
export type BasicResourceProjection = z.infer<
  typeof BasicResourceProjectionSchema
>

export const ContentBuilderProjectionSchema = z.object({
  featuredImage: SanityImage,
  title: z.string(),
  featuredText: z.array(SanityBlock),
  url: z.string(),
})

export const SpotlightProjectionSchema = z.object({
  showSpotlight: z.boolean(),
  featuredImage: SanityImage,
  title: z.string(),
  featuredText: z.array(SanityBlock),
  buttonText: z.string(),
  url: z.string(),
})
export type SpotlightProjection = z.infer<typeof SpotlightProjectionSchema>

export const TrendingResourcesResponseSchema = z.object({
  slugs: z.array(z.string()),
})
export type TrendingResourceResponse = z.infer<
  typeof TrendingResourcesResponseSchema
>

const GatedContentSchema = z.object({
  type: z.enum(['content', 'redirect']),
  redirectUrl: z.string().optional(),
  body: z.array(SanityBlock),
})
export type GatedContent = z.infer<typeof GatedContentSchema>

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share'

import {faCopy} from '@fortawesome/pro-light-svg-icons'
import {faShare} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import styles from '../../styles/modules/SocialShareButton.module.scss'
import {trackUserEngagement} from '../analytics/tracking/trackUserEngagement'
import {getSlugFromUrl} from '../resources/utils'

interface SocialShareButtonProps {
  title: string
  url: string
}

export function SocialShareButton({title, url}: SocialShareButtonProps) {
  const copyLinkToClipboard = async () => {
    await navigator.clipboard.writeText(url)
    trackUserEngagement({
      action: 'Share',
      subaction: 'Copied Link',
      documentType: 'Resource',
      slug: getSlugFromUrl(url),
    })
  }

  const onShare = (platform: 'Email' | 'LinkedIn' | 'Facebook' | 'X') => () => {
    trackUserEngagement({
      action: 'Share',
      subaction: platform,
      documentType: 'Resource',
      slug: getSlugFromUrl(url),
    })
  }

  return (
    <div className="dropdown d-flex flex-grow-1 flex-lg-grow-0">
      <div
        role="button"
        data-bs-toggle="dropdown"
        className={`btn btn-outline-primary dropdown-toggle d-flex justify-content-center align-items-center py-3 py-sm-1 ${styles.hideCaret}`}
      >
        <FontAwesomeIcon icon={faShare} className="me-0 me-sm-2" size="lg" />
        <span className="d-none d-sm-block">Share</span>
      </div>
      <div className="dropdown-menu">
        <div className="dropdown-item">
          <EmailShareButton
            // prevent default needed otherwise the current tab will navigate to the email client
            onClick={(e) => e.preventDefault()}
            onShareWindowClose={onShare('Email')}
            openShareDialogOnClick
            subject={'Check out this story from Eyes On Eyecare'}
            url={url}
          >
            <EmailIcon size={16} round className="me-2" />
            Share via Email
          </EmailShareButton>
        </div>
        <div className="dropdown-item">
          <LinkedinShareButton
            title={title}
            url={url}
            onShareWindowClose={onShare('LinkedIn')}
          >
            <LinkedinIcon size={16} round className="me-2" />
            Share on LinkedIn
          </LinkedinShareButton>
        </div>
        <div className="dropdown-item">
          <FacebookShareButton
            url={url}
            onShareWindowClose={onShare('Facebook')}
          >
            <FacebookIcon size={16} round className="me-2" />
            Share on Facebook
          </FacebookShareButton>
        </div>
        <div className="dropdown-item">
          <TwitterShareButton
            title={title}
            url={url}
            onShareWindowClose={onShare('X')}
          >
            <XIcon size={16} round className="me-2" />
            Share on X
          </TwitterShareButton>
        </div>
        <div className="dropdown-item">
          <div
            className="d-flex align-items-center"
            onClick={copyLinkToClipboard}
            style={{cursor: 'pointer'}}
          >
            <FontAwesomeIcon icon={faCopy} className="me-2" size="1x" />
            Copy Link
          </div>
        </div>
      </div>
    </div>
  )
}
